@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap");

@font-face {
  font-family: "amithem";
  src: url("../font/amithen.ttf");
}

/*::-webkit-scrollbar {*/
/*  width: 12px;  !* for vertical scrollbars *!*/
/*  height: 12px; !* for horizontal scrollbars *!*/
/*}*/

/*!* This will change the scrollbar thumb appearance *!*/
/*::-webkit-scrollbar-thumb {*/
/*  background: rgba(0, 0, 0, 0.5);*/
/*}*/
body {
  font-family: "Barlow Condensed", sans-serif;
  background-color: rgb(247, 243, 238);
  overflow-x: hidden;
  width: 100%;
}

/* .modal {
  overflow: visible;
} */

.all-content-wrapper {
  width: 100%;

  height: 100vh;

  background-color: aliceblue;
}

/* Css for colorful lines */

.colorful-lines-container {
  width: 100%;

  height: 100%;

  position: fixed;

  top: 0;

  left: 0;

  background-color: #f7f3ee;

  display: flex;

  align-items: center;

  justify-content: center;
}

.color-line {
  width: calc(100% / 7);

  height: 100vh;

  position: absolute;

  top: -120%;

  animation-name: line-from-top;

  animation-duration: 1.5s;

  animation-fill-mode: forwards;
}

.color-line1 {
  background-color: #e6213c;

  left: 0;

  animation-delay: 0.4s;
}

.color-line2 {
  background-color: #fab627;

  left: calc(100% / 7);

  animation-delay: 0.6s;
}

.color-line3 {
  background-color: #17b5c2;

  left: calc(100% / 3.5);

  animation-delay: 0.8s;
}

.color-line4 {
  background-color: #33ab53;

  animation-delay: 1s;
}

.color-line5 {
  background-color: #2f3e3f;

  right: calc(100% / 3.5);

  animation-delay: 1.2s;
}

.color-line6 {
  background-color: #f7f3ee;

  right: calc(100% / 7);

  animation-delay: 1.4s;
}

.color-line7 {
  background-color: #ffffff;

  right: 0;

  animation-delay: 1.6s;
}

/* Css for V image */

.center-v-img {
  width: 100%;

  height: 100%;

  position: fixed;

  left: 0;

  top: 0;

  z-index: 100;
}

.center-v-img img {
  position: absolute;

  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);

  opacity: 0;

  animation: v-logo-lazy 1s ease 2.4s 1 normal forwards;
}

/* Css for bottom images */

.bottom-images-container {
  width: 100%;

  height: 80vh;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  background-color: #fff;

  position: fixed;

  bottom: -100%;

  left: 0;

  animation-name: images-from-bottom;

  animation-duration: 2.5s;

  animation-delay: 2.8s;

  animation-fill-mode: forwards;

  z-index: 1000;
}

.bottom-logo,
.bottom-text-img {
  width: 100%;

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
}

.bottom-logo img {
  width: 280px;
}

.bottom-text-img img {
  width: 300px;
  opacity: 0;
  animation-name: img-fade;

  animation-duration: 2.5s;

  animation-delay: 5.4s;

  animation-fill-mode: forwards;
}

/* Css for animations */

@keyframes line-from-top {
  0% {
    top: -120%;
  }

  100% {
    top: 0;
  }
}

@keyframes v-logo-lazy {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes images-from-bottom {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0%;
  }
}

@keyframes img-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.joinScreen {
  padding: 2rem;
  text-align: center;
}

.joinHdng {
  /* font-size: 20px; */
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 700;
  color: #2f3e3f;
}

.joinSubtext {
  /* font-size: 17px; */

  text-transform: uppercase;

  font-weight: 500;

  margin-top: 5px;
}

.vapiano_table {
  display: flex;

  align-items: center;

  width: 100%;

  border-radius: 25px;

  border: 1px solid #fff;

  height: 38px;

  padding: 10px 20px;

  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.vapiano_table::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b6b6b6;
}

.btntable {
  width: 113px;

  height: 40px;

  border: 1px solid transparent;

  border-radius: 25px;

  text-transform: uppercase;

  /* color: #fff; */

  /* font-size: 16px; */

  font-weight: 500;
}

.bggreen {
  background-color: #33ab53;
}

.bgblue {
  background-color: #17b5c2;
}

.textgreen {
  color: #33ab53;
}

.textblue {
  color: #17b5c2;
}

.bgpinkish {
  background-color: #f7f3ef;
  min-height: 97vh;
}

.header {
  background-color: #17b5c2;
  z-index: 10;
  display: flex;

  align-items: center;

  justify-content: space-between;

  height: 55px;

  position: fixed;

  top: 0;

  z-index: 110;

  width: 100%;
}

.formShow.active {
  top: 55px;
  opacity: 1;
  z-index: 1;
  height: 73px;
  padding: 20px;
}

.header form {
  width: 100%;
  height: 0px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 55px;
  opacity: 1;
  z-index: -1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.header form i {
  color: #404040;
  cursor: pointer;
}

.header form input {
  outline: none;
  border: 1px solid #404040;
  width: calc(100% - 70px);
  height: 32px;
}

.header form input:focus {
  outline: none;
}

.header form button {
  width: 70px;
  height: 32px;
  background: #17b5c2;
  color: #fff;
  border: none;
}

.forPosRel {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.pdngbtom {
  padding-bottom: 3.4rem;
  transition: 0.3s;
}

.header p {
  color: #fff;

  font-size: 16px;

  font-weight: 400;

  margin-bottom: 0px;

  font-family: "Barlow Condensed", sans-serif;
}

.header i {
  color: #fff;

  margin-left: 2rem;

  font-size: 20px;
}

.header .location {
  display: flex;

  align-items: center;
}

.removeHighlight:focus {
  outline: none !important;
  box-shadow: none;
  border: none;
}

.searchlocation {
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding-right: 18px;
}

.searchlocation span {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
  font-family: "Barlow Condensed", sans-serif;
}

.searchlocation i {
  cursor: pointer;
  color: #000;
  margin-left: 0px;
  margin-right: 15px;
}

.header .location i {
  cursor: pointer;
  color: #fff;
  margin-left: 20px;
  margin-right: 15px;
}

.logo {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
}

.mainLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.liveBtn {
  width: 40px;
  height: 40px;
  border: none;
  background: #e61a3e;
  color: #fff;
  border-radius: 50%;
  font-size: 17px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* .logo img {
  width: 245px;
} */
.bigThank {
  font-size: 35px;
  font-weight: 600;
  color: #e6213c;
}

main {
  padding-bottom: 5.7rem;
}

.aft_logo_text {
  text-align: center;
}

.aft_logo_text h3 {
  font-size: 20px;

  font-weight: 600;

  margin-bottom: 0px;

  font-family: "Barlow Condensed", sans-serif;
}

.modal-content {
  border-radius: 0.6rem !important;
}

.greytext {
  color: #2f3e3f;
}

.bluetext {
  color: #17b5c2;
}

.colortheme {
  display: flex;

  margin-top: 10px;

  margin-bottom: 10px;
}

.colortheme a {
  width: calc(100% / 7);

  height: 50px;

  display: inline-block;
}

.colortheme a.clr1 {
  background-color: #e6213c;
}

.colortheme a.clr2 {
  background-color: #fab627;
}

.colortheme a.clr3 {
  background-color: #17b5c2;
}

.colortheme a.clr4 {
  background-color: #33ab53;
}

.colortheme a.clr5 {
  background-color: #2f3e3f;
}

.colortheme a.clr6 {
  background-color: #f7f3ee;
}

.colortheme a.clr7 {
  background-color: #fff;
}

.navheader {
  background-color: #f7f3ee;
  height: 43px;
}

.navitem {
  font-size: 19px;

  text-transform: uppercase;

  color: #2f3e3f;

  text-decoration: none;

  font-weight: bold;

  font-family: "Barlow Condensed", sans-serif;
}

.sectionHdng {
  display: flex;
  align-items: center;
  padding: 5px 1.5rem;
  padding-right: 0;

  justify-content: space-between;
}

.hdngflex {
  margin-right: 1rem;

  position: relative;
}

.hdngflex h1 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 5px;
  color: #17b5c2;
  /* color: #e6213c; */
  line-height: 43px;
  white-space: nowrap;
  text-transform: uppercase;
}

.hdnglineblue {
  position: relative;
  height: 5px;
  /* background-color: #17b5c2; */
  /* background-color: #E6213C; */
  bottom: 12px;
}

.vegitarian .hdngflex h1 {
  color: #33ab53;
  font-size: 30px;
  font-weight: 600;
}

.vegitarian .hdnglineblue {
  /* background-color: #33ab53; */
}

.sectionlight .hdngflex h1 {
  color: #2f3e3f;

  font-size: 37px;
}

.font {
  font-family: "amithem";

  font-size: 34px;

  position: absolute;

  top: 0.4em;

  z-index: 2;
}

.hdngline {
  height: 5px;

  width: 100%;

  /* background-color: #17b5c2; */
  /* background-color: #E6213C; */
}

.divfooter {
  background-color: #e6213c;

  padding: 3rem 2rem;
  margin-top: 50px;
}

.logo.logo_footer {
  background-color: transparent;
}

.logo_footer h1 {
  color: #fff;

  font-size: 52px;

  font-family: "Shadows Into Light", cursive;
}

.divfooter p {
  color: #fff;

  margin-bottom: 0px;

  font-size: 15px;

  text-align: justify;
}

.sectionSlider {
  margin-top: 3rem;

  margin-bottom: 2rem;
}

.itemImg {
  padding: 0.5rem;
}

.itemImg a {
  text-decoration: none;
}

.section_menu {
  margin-top: 1.5rem;
}

.itemImg h1 {
  font-size: 17px;

  margin-bottom: 2px;

  text-align: center;

  font-weight: 700;

  text-transform: uppercase;

  color: #2f3e3f;
}

.itemImg img {
  height: 144px;

  object-fit: cover;

  margin: auto;

  width: 94%;
}

.item_btm {
  display: flex;

  justify-content: center;

  margin-top: 2px;

  position: relative;
}

.item_info {
  position: absolute;

  left: 8px;

  font-size: 18px;

  cursor: pointer;
}

.item_info i {
  color: #2f3e3f;
}

.item_price {
  font-size: 18px;

  color: #2f3e3f;

  font-weight: bold;
}

.sectionlight .sectionSlider {
  margin-top: 2rem;
}

.sectionlight .sectionHdng {
  align-items: center;
}

.main_navigation {
  background-color: #2f3e3f;

  height: 105px;

  position: fixed;

  bottom: 0;

  width: 100%;

  display: flex;

  align-items: center;

  justify-content: space-between;

  z-index: 100;
  padding-bottom: 15px;
}

.main_navigation ul {
  padding-left: 0px;

  margin-bottom: 0px;

  display: flex;

  align-items: center;

  justify-content: space-between;

  width: 100%;
}

.main_navigation ul li {
  list-style-type: none;

  text-align: center;
}

.main_navigation ul li a {
  display: flex;

  align-items: center;

  justify-content: center;

  flex-direction: column;

  line-height: 19px;

  padding: 11px;

  color: #fff;

  text-decoration: none;
}

.main_navigation ul li a.active {
  color: #17b5c2;
}

.main_navigation ul li a.active svg g {
  fill: #17b5c2 !important;
}

.main_navigation ul li a:hover svg g {
  fill: #17b5c2 !important;
}

.nav_icon {
  font-size: 24px;

  margin-bottom: 2px;
}

.nav_icon svg {
  height: 30px;

  width: 30px;
}

.nav_text {
  text-transform: uppercase;

  font-size: 12px;
}

.input-counter {
  display: flex;

  align-items: center;
}

.btninc {
  background-color: transparent;

  border: none;

  font-size: 20px;

  padding: inherit;
}

.counter {
  border: none;

  width: 35px;

  text-align: center;

  font-size: 24px;

  background-color: #f7f3ee;
}

.modalHdr {
  padding: 1rem;

  display: flex;

  justify-content: space-between;
}

.mdltitle {
  font-size: 22px;
  margin-top: 10px;
  margin-left: 9px;
  font-weight: bold;

  color: #17b5c2;

  margin-bottom: 0px;
  z-index: 1;
  text-transform: uppercase;
}

.mdlicon {
  margin-right: 55px;
  z-index: 1;
  font-size: 20px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.mdlicon i {
  margin-left: 10px;
}

.modalimg {
  text-align: center;

  padding: 1rem;

  padding-top: 0rem;
}

.modalimg img {
  width: 100%;

  height: 240px;

  object-fit: cover;
}

.modaldesc,
.checklist {
  padding-left: 1rem;

  padding-right: 1rem;
}

.checklist h1 {
  font-weight: bold;
}

.checklist ul {
  padding-left: 0px;

  margin-left: 1.3rem;
}

.checklist ul li {
  list-style-type: none;

  padding: 10px;

  padding-left: 0px;

  font-weight: bold;

  font-size: 17px;

  border-bottom: 2px solid #2f3e3f;

  display: flex;

  justify-content: space-between;
}

.minusPopup {
  height: 24px;

  width: 27px;

  background-color: grey;

  border: 1px solid grey;

  font-size: 24px;

  line-height: 14px;

  color: #fff;
}

.minusPopup.active {
  background-color: #17b5c2;

  border-color: #17b5c2;
}

.plusPopup {
  height: 24px;

  width: 27px;

  background-color: grey;

  border: 1px solid grey;

  font-size: 24px;

  line-height: 14px;

  color: #fff;
}

.plusPopup.active {
  background-color: #17b5c2;

  border-color: #17b5c2;
}

.checklist ul li span {
  float: right;
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 2px;
  font-size: 13px;
}

.checklist ul li label {
  display: block;
}

.modalftr {
  background-color: #f7f3ee;

  padding: 1rem;
}

#orderModal .modal-body {
  padding: 0rem;
}

.mdlclose {
  /* position: absolute;

    right: 11px;

    top: 0em; */

  color: #e6213c;

  cursor: pointer;

  font-size: 25px;
}

.modalData {
  margin-top: 2rem;
}

.btncart {
  display: flex;

  background-color: #e6213c;

  align-items: center;

  justify-content: center;

  height: 37px;

  border: 1px solid #e6213c;

  border-radius: 30px;

  font-size: 17px;

  color: #fff;

  text-decoration: none;

  text-transform: uppercase;
}

.cart-extras {
  float: right;
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 2px;
  font-size: 13px;
  color: #fff;
  /* background-color: #17B5C2; */
  background-color: #e61a3f !important;
}

html {
  scroll-behavior: smooth;
}

.extras {
  float: right;
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 2px;
  font-size: 13px;
}

.cart-extrasplus {
  /* color: #fff; */
  border: 1px solid black;
  /* background-color: #17B5C2; */
  background-color: #fff !important;
}

.innericon {
  font-size: 35px;

  padding-right: 1rem;

  color: #2f3e3f;
}

.scbginner {
  background-color: #f7f3ee;
}

.quant_pdng {
  padding-left: 2rem;
  padding-right: 3.7rem;
}

.billstyle {
  font-size: 18px;

  padding-bottom: 5px;

  font-weight: bold;
}

.brdrbtm {
  height: 2px;

  background-color: #e6213c;

  width: 100%;
}

.total {
  display: flex;

  justify-content: flex-end;

  font-size: 26px;

  font-weight: bold;

  margin-top: 10px;

  color: #2f3e3f;
}

.placebtn {
  margin-top: 2rem;

  margin-bottom: 2rem;

  display: flex;

  justify-content: space-between;
}

.placebtn a {
  display: flex;

  width: 100%;

  margin: 6px;

  text-decoration: none;
}

.btncheck {
  width: 100%;

  /* height: 35px; */

  border-radius: 30px;

  border: 1px;

  color: #fff;

  text-transform: uppercase;
}

.btncheck.btnblue {
  background-color: #17b5c2;
}

.btncheck.btnred {
  background-color: #e6213c;
}

h4.light {
  font-size: 21px;

  color: #17b5c2;

  margin-left: 1.7rem;

  margin-bottom: 2px;

  margin-top: 15px;
}

.coursetitle {
  font-size: 20px;

  color: #17b5c2;

  margin-left: 1.7rem;

  margin-bottom: 15px;
}

.orderlist {
  display: flex;

  justify-content: space-between;

  background-color: #fff;

  padding: 10px 2em;

  border-bottom: 1px solid #ddd;

  align-items: center;
}

.caretupdown {
  display: flex;

  flex-direction: column;

  font-size: 10px;

  line-height: 16px;
  cursor: pointer;
}

.btnplace {
  background-color: #e6213c;

  width: 150px;

  height: 35px;

  border-radius: 30px;

  border: 1px;

  color: #fff;

  text-transform: uppercase;

  margin-top: 3rem;

  margin-bottom: 1rem;
}

.plceor {
  margin: 2rem;

  display: flex;

  justify-content: flex-end;
}

.thnkuModal {
  text-align: center;

  margin-top: 2rem;

  margin-bottom: 3rem;
}

.thnkuModal h1 {
  font-size: 38px;

  font-weight: bold;

  color: #17b5c2;
}

.order-data h6 {
  font-size: 22px;

  color: #2f3e3f;

  margin-bottom: 0px;

  font-weight: 600;
}

.order-data h4 {
  font-size: 38px;

  color: #2f3e3f;

  margin-bottom: 0px;

  font-weight: 600;
}

.order-buttons {
  padding-top: 2rem;

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
}

.btnorderpi {
  width: 180px;

  margin-bottom: 14px;

  height: 38px;

  border-radius: 30px;

  color: #fff;

  text-transform: uppercase;

  font-size: 20px;

  display: flex;

  align-items: center;

  justify-content: center;

  text-decoration: none;
}

.btnorderpi.btnlight {
  background-color: #17b5c2;
}

.btnorderpi.btngreen {
  background-color: #16dd64;
}

.accordion-body {
  text-align: start !important;
}

.helpModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-button {
  outline: none !important;
}

.accordion-button:focus {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: unset !important;
  color: unset !important;
}

.accordion-button:not(.collapsed) {
  color: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.introSkip-btn {
  width: 100px;
  margin-bottom: 14px;
  height: 38px;
  border-radius: 30px;
  border: none;
  font-size: 18 px;
  color: #f3f3f3;
  background-color: #33ab52;
}

.introModalImg {
  margin-top: -5px;
  padding: 2px;
  max-width: 100%;
  height: auto;
}

/* Css for responsive */

@media (max-width: 560px) {
  .bottom-logo img {
    width: 250px;
  }

  .order-ing-name {
    font-size: 14px;
    white-space: normal;/* Prevents the item name from wrapping to the next line */
  }
  .name-wrapper {
    flex-grow: 1; /* Allow the name to occupy the available space */
  }
  .order-ing-price {
    font-size: 14px;
  }
  .price-wrapper {
    display: flex;
    align-items: baseline;
  }
  .ingredient-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order-ing-price {
    font-size: 14px;
    display: inline-flex;
    align-items: baseline; /* Keeps the Euro and price baseline aligned */
  }

  .mainLogo img {
    width: 220px;
  }

  .boardDrawing {
    width: 100;
    height: 50;
  }

  .bottom-text-img img {
    width: 250px;
  }

  .heartImg {
    bottom: 40px;
    right: 20px;
  }
}

@media (max-width: 370px) {
  .bottom-logo img {
    width: 200px;
  }

  /* .cate-icons {
    height: 10px !important;
  } */
  .bottom-text-img img {
    width: 220px;
  }
}

@media (max-width: 355px) {
  .dish-img-container {
    height: 115px !important;
    width: 115px !important;
  }

  .dish-img {
    height: 115px !important;
  }
}

@media (max-width: 328px) {
  .dish-img-container {
    height: 105px !important;
    width: 105px !important;
  }

  .dish-img {
    height: 105px !important;
  }
}

@media (max-width: 576px) {
  .billItems {
    padding-right: 30px;
  }

  .menu-itemPrice {
    margin-left: 40px;
  }

  .subAmount {
    margin-right: 30px;
  }

  .dish-img-container {
    height: 150px !important;
  }

  .dish-img {
    height: 150px !important;
  }
}

@media (max-width: 760px) {
  .menu-itemPrice {
    margin-left: 40px;
  }
}

/* //css for iphone 12 mini */
@media (max-width: 377px) {
  .queueTime {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 101px;
    height: 100%;
    background-color: #17b5c2;
    color: #fff;
  }

  .queueTime i {
    margin-left: 10px;
    font-size: 22px !important;
  }

  .queueItemName img {
    margin-right: 10px;
    width: 40px;
    height: 33px;
    object-fit: contain;
  }

  .queueItemName h1 {
    font-size: 30px !important;
    color: #17b5c2;
  }
}

/* For width below 490 */
@media (width <=490px) {
  .removeOn490 {
    display: none;
  }
}

@media (width > 490px) {
  .removeOn491 {
    display: none;
    visibility: hidden;
  }
}

@media (width < 490px) {
  .showOn490 {
    visibility: visible;
  }
}

.inactive-navlink,
.inactive-navlink:hover {
  color: #f3f3f3;
  font-size: 12px;
}

.active-navlink,
.active-navlink:hover {
  color: #e6213c;
  font-size: 12px;
}

.active-navlink svg g,
.active-navlink svg g:hover {
  fill: #17b5c2 !important;
  font-size: 12px;
}

.active-navlink-bill svg g,
.active-navlink-bill svg g:hover {
  fill: #33ab53 !important;
  font-size: 12px;
}

.active-navlink-bill,
.active-navlink-bill:hover {
  color: #33ab53;
  font-size: 12px;
}

.active-navlink-guest svg g,
.active-navlink-guest svg g:hover {
  fill: #fab627 !important;
  font-size: 12px;
}

.active-navlink-guest,
.active-navlink-guest:hover {
  color: #fab627;
  font-size: 12px;
}

.active-navlink-service svg g,
.active-navlink-service svg g:hover {
  fill: #e6213c !important;
  font-size: 12px;
}

.active-navlink-service,
.active-navlink-service:hover {
  color: #e6213c;
  font-size: 12px;
}

.orders-title {
  color: #17b5c2;
  padding-left: 10px;
  font-size: 30px;
  font-weight: 600;
}

.orders-courses {
  /* color: #17b5c2; */
  /* font-size: 21px; */
  padding-left: 25px;
  margin-top: 15px;
}

.orders-item {
  /* color: #17B5C2; */
  margin-top: 0px !important;
  /* font-size: 17px; */
  font-weight: 600;
}

.sectionHdng {
  padding: 5px 7px;
  padding-right: 0;
}

.sectionHdng {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hdnglineblue {
  height: 4px;
  width: calc(100% - 6px);
  margin-left: 4px;
  /* background-color: #17b5c2; */
  background-color: #e6213c;
}

.hdngline {
  height: 4px;
  width: calc(98% - 6px);
  margin-left: 4px;
}

.leafsImg {
  width: 80px;
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-60%);
}

.hdngflex h1 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 5px;
  /* color: #17b5c2; */
  color: #e6213c;
  line-height: 43px;
  white-space: nowrap;
}

.hdnglineblue {
  position: relative;
  height: 2px;
  /* background-color: #17b5c2; */
  /* background-color: #e6213c; */
  bottom: -1px;
}

.hdnglineyellow {
  position: relative;
  height: 2px;
  width: 100%;
  /* background-color: #17b5c2; */
  /* background-color: #f9d71c; */
  bottom: -1px;
}

.hdngWrap {
  position: relative;
  /* top: -9px; needed to align on center*/
  right: 3px;
  width: 100%;
}

.vegitarianSpeImg {
  position: absolute;
  left: 0;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.vegitarianSpeImg img {
  width: 20px;
}

.hdngflex {
  margin-right: 8px;
  position: relative;
}

.cart-modal .modal-dialog {
  height: calc(100% - 1rem);
}

.cart-modal .modal-dialog .modal-content {
  height: 100%;
}

.cart-modal .modal-body {
  max-height: unset;
}

.billHdngflex {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 5px;
  line-height: 43px;
  white-space: nowrap;
}

.billHdng {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.billHandwritten {
  font-family: "amithem";
  font-size: 40px;
  position: absolute;
  top: 0.5em;
  z-index: 2;
  color: #212529;
}

.divideThank {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -25px;
}

.divideThank h1 {
  font-size: 37px;
  font-weight: 600;
  color: #17b5c2;
  margin-bottom: 60px;
}

.divideThank h2 {
  font-family: "amithem";
  font-size: 51px;
  position: absolute;
  top: 19px;
}

.billbtntextmain {
  margin-top: 2rem;
  margin-bottom: 7rem;
}

.billButtonGroup {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.billALLButtonGroup {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.button-paypal-login {
  margin-top: 10px;
  color: #fff;
  background-color: #0070ba;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  font-family: "Heebo", sans-serif;
}

.billButton {
  width: 32%;
  margin: 6px 0 !important;
  /* min-height: 35px; */
  border-radius: 30px;
  border: 1px;
  color: #fff;
  text-transform: uppercase;
  background-color: #33ab53;
  font-size: 17px;
}

@media only screen and (max-width: 404px) {
  /* .billButtonGroup {
    width: 350px;
  } */
  .billButton {
    width: 32%;
    font-size: 13px;
    margin: 3px 0 !important;
    border-radius: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media only screen and (max-width: 623px) {
  .billButton {
    font-size: 13px;
  }
}

.amountWithTip2 {
  margin-top: px;
  display: flex;
  justify-content: space-between;
}

.amountWithTip2 span {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  /* color: #17b5c2; */
}

.amountWithTip2 b {
  font-weight: bold;
}

.amount-of-tip {
  font-size: 20px !important;
}

.amount-of-tip-total {
  font-size: 20px !important;
  font-weight: bold !important;
}

.paymentWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.btnPay {
  height: 70px;
  width: 35%;
  min-width: 100px;
}

.btnPayBill {
  height: 80px;
  /* width: 15%; */
  min-width: 90px;
}

.btnPayBill p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.btnPay p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.paymentButton {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 5px 5px 10px -5px #000;
}

.paymentButton2 {
  color: black;
  background-color: #31a44f;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  /* box-shadow: 5px 5px 10px -5px #000; */
}

.paymentBillButton2 {
  color: black;
  background-color: #31a44f;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  width: 120px;
}

.paymentWaiterButton2 {
  color: black;
  background-color: #f7f3ee;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  width: 120px;
}

/* .paymentButton2 p {
  margin: 0;
} */

.tipEmoji {
  display: flex;
  margin-bottom: 15px;
  margin-top: 10px;
}

.singleTip {
  width: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 5px;
}

.singleTip img {
  width: 100%;
  margin-bottom: 10px;
}

.singleTip input {
  transform: scale(1.4);
  margin-bottom: 5px;
}

.tipWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tipWrapInput {
  height: 40px;
  border: 1.5px solid #000;
  margin-right: 5px;
  width: 225px;
  font-size: 23px;
  outline: none;
  text-align: center;
}

.tipAmount {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tick-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 45px;
  border-radius: 10px;
}

.singleTip input[type="checkbox"] {
  color: #59ba73;
}

.paypalPay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 0.3s;
  font-family: "Heebo", sans-serif;
  padding-top: 0px;

  /* padding-top: 85px; */
}

.divideCalc2,
.divideCalc3 {
  width: 100%;
  padding: 0px 15px;
  background: #ffffff;
  z-index: 1000;
  transition: 0.3s;
  padding-top: 0px;
}

.creditHeader h1 {
  font-size: 21px;
  font-weight: 400;
  font-family: "Heebo, sans-serif";
}

.cardNum,
.cvvNum,
.dobNum {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.cardOption {
  display: block;
  width: 120px;
  text-align: left;
  font-size: 16px;
  font-family: "Heebo, sans-serif";
}

.paypalform {
  width: 100%;
}

.paypalform input {
  width: 100%;
  height: 45px;
  border: 1.5px solid #c1c1c1;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 0 10px;
  font-family: "Heebo", sans-serif;
}

.paypalform input::placeholder {
  font-size: 16px;
}

.paypalform input:focus {
  outline: none;
}

.paypalform .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.paypalform .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1.5px solid skyblue;
  border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.paypalform .container:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
.paypalform .container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.paypalform .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.paypalform .container .checkmark:after {
  left: 9px;
  top: 4px;
  width: 5px;
  height: 13px;
  border: solid #545454;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.paypalform .container span {
  font-size: 15px;
}

.stayLog {
  margin-top: 10px;
}

.stayLog img {
  width: 18px;
  margin-left: 7px;
}

.paypalform button {
  margin-top: 10px;
  color: #fff;
  background-color: #0070ba;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  font-family: "Heebo", sans-serif;
}

.billTotal {
  background-color: #f7f3ee;
  border-radius: 8px;
  /* overflow: hidden; */
  /* position: relative; */
  /* margin-bottom: 1rem; */
  /* padding-top: 10px;
  padding-bottom: 10px; */
}

/* .billTotal p {
  font-size: 30px;
  font-weight: 500;
  color: #555555;
  font-weight: 600;
  text-align: center;
  margin: 0;

} */

/* .billTotal img {
  position: absolute;
  width: 50px;
  bottom: 40px;
  left: 50%;
  transform: translateX(-260%);
} */

.dividePara {
  font-size: 23px;
  color: #555555;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divideLabel {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

.divInp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.divInp button {
  height: 40px;
  width: 40px;
  border: none;
  background: #00a94d;
  color: #fff;
  border-radius: 8px;
}

.divInpBox {
  height: 40px;
  border: 1px solid #000;
  width: 85%;
  margin-right: 5px;
  font-size: 23px;
  outline: none;
  text-align: center;
  border-radius: 0%;
}

.totalPr {
  color: #17b5c2;
  font-size: 53px;
  text-align: center;
}

/* //live modal css */
.queueItemName img {
  margin-right: 10px;
  width: 45px;
  height: 25px;
  object-fit: contain;
}

.liveTop {
  text-align: center;
  margin-top: -15px;
  margin-bottom: 13px;
}

.queueItemName h1 {
  font-size: 30px;
  color: #17b5c2;
}
html {
  scroll-behavior: smooth;
}

.scrollbtn {
  position: sticky;
  bottom: -9px;
}

.dish-wrapper {
  display: inline-block; /* or inline-flex, depending on your needs */
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper .swiper-slide {
  margin: 0;
  padding: 0;
}

.dish-img-container img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.dish-img1 {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: auto;
  vertical-align: middle;
}

.banner-image {
  padding: 5px;
  height: 25px;
  width: 25px;
  position: absolute;
  background: red;
  top: 0;
  right: 15px;
  border-radius: 20px;
  cursor: pointer !important;
}

@media (max-width: 425px) {
  .banner-image {
    width: 20px;
    height: 20px;
    right: 5px;
    top: 0;
  }
}


.dish-img-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.dish-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dish-img-container {
  width: 100%;
  height: 168px;  /* Adjust this height as necessary */
  overflow: hidden;
}
.order-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.price-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.price-info-container b {
  flex-grow: 1;
  text-align: right;
}
.cate-icons {
  /* Adjust this height if needed */
  height: 18px;
}
.dish-img1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
  vertical-align: middle;
}
.liveBottom p {
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  color: #2e4042;
}

.mdlclose {
  color: #e6213c;
  cursor: pointer;
  font-size: 25px;
}

#liveModal .mdlclose {
  position: absolute;
  top: 0px;
  right: 10px;
}

.liveTop h1 {
  font-weight: 600;
  color: #e6213c;
  margin-bottom: 0;
}

#liveModal h3 {
  font-size: 26px;
  margin-top: -8px;
  font-weight: 600;
}

.liveBottom p {
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  color: #2e4042;
}

.liveBottom ul {
  list-style: none;
  padding-left: 0;
}

.queueItem li {
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 8px 8px 7px -5px #dadada;
  margin-bottom: 17px;
}

.queueItemName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 135px);
  height: 100%;
  background-color: #f7f3ee;
  padding-left: 10px;
}

.queueItemName img {
  margin-right: 10px;
  width: 45px;
  height: 33px;
  object-fit: contain;
}

.queueTime {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 100%;
  background-color: #17b5c2;
  color: #fff;
}

.queueTime span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.queueItemName h1 {
  font-size: 39px;
  color: #17b5c2;
}

.queueTime i {
  margin-left: 10px;
  font-size: 30px;
}

.vapYellow {
  background-color: #fab627;
}

.vapRed {
  background-color: #e6213c;
}

.vapGreen {
  background-color: #33ab53;
}

.category-icons {
  position: absolute;
  right: 60px;
  transform: translateY(-40%);
}

.countTotalOrder {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  /* background-color: #e6213c; */
  background-color: rgb(23, 181, 194);
  position: absolute;
  top: -10px;
  left: -15px;
  color: #fff;
  font-size: 16px;
}

@keyframes totalOrder {
  from {
    background-color: rgb(23, 181, 194);
  }

  to {
    background-color: red;
  }
}

.mainLogoimg {
  width: 50px;
}

.bold-600 {
  font-weight: 600;
}

.font14 {
  font-size: 14px;
}

.suggestion-options {
  height: 65px;
  width: 60px;
}

.dish-cross-modal {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 7px;
  width: 27px;
  height: 27px;
  z-index: 999;
  border-radius: 50%;
}

.dish-cross-modal i {
  display: flex;
  background-color: #dc3545;
  font-size: 20px;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  margin-left: 1px;
  align-items: center;
  justify-content: center;
  border: none;
}

.dish-cross-modal i:hover {
  background-color: #3562dc;
}

/* .fa-stack-1x {
  margin-left: 2px !important;
} */

.dish-wrapper {
  height: fit-content;
}

.dish-img-container {
  width: 100%;
  height: 168px;
  padding-left: 7px;
  object-fit: cover;
  /* margin: auto; */
  position: relative;
  margin-bottom: 5px;
}

.dish-img {
  width: 100%;
  /* min-width: 100%; */
  height: 168px;
  object-fit: cover;
  margin: auto;
  vertical-align: middle;
}

.dish-img1 {
  width: 400px;
  /* min-width: 100%; */
  height: 168px;
  object-fit: cover;
  margin: auto;
  vertical-align: middle;
}

.dish-img2 {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: auto;
  vertical-align: middle;
}

@media (max-width: 800px) {
  .dish-img1 {
    width: 250px;
  }
}

@media (min-width: 543px) {
  .dish-img1 {
    width: 400px !important;
    height: 150px !important;
  }
}

@media (min-width: 400px) {
  .dish-img1 {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 400px) {
  .dish-img1 {
    width: 120px;
    height: 120px;
  }
}

.heartImg {
  position: absolute;
  bottom: 10px;
  margin-left: auto;
  right: 15px;
  width: 25px !important;
}

.nav-header {
  background-color: #f7f3ee;
  height: 40px;
  display: flex;
  margin-bottom: 10px;
  position: sticky;
  /* top: 37px; */
  z-index: 100;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.nav-item {
  font-size: 17px;
  text-transform: uppercase;
  color: #2f3e3f;
  text-decoration: none;
  font-weight: bold;
  font-family: "Barlow Condensed", sans-serif;
  /* margin-top: 5px; */
}

/* .thankuOrder {
  font-size: 42px;
  font-weight: 600;
  color: #17b5c2;
} */

.canSuggest {
  font-size: 24px;
  font-weight: 600;
  color: #404040;
}

.relatedmodal-img {
  position: relative;
}

.relatedmodal-img-overlay {
  position: absolute;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  display: flex;
  align-items: center;
  justify-content: center;
}

.context-out-of-stock > img {
  height: 50px;
}

.context-out-of-stock > p {
  margin-top: 5px;
  color: white;
}

.orderModalImg {
  border: none;
  width: 100%;
  height: 144px;
  object-fit: cover;
  margin: auto;
}

.suggestBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.suggestBtn button {
  width: 133px;
  height: 45px;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  background: #17b5c2;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* box-shadow: 5px 5px 10px -5px #000; */
}

.sugGo {
  background-color: #e6213c !important;
}

.suggestBtn button i {
  font-size: 15px;
  margin-left: 9px;
  margin-top: 3px;
}

.orGo {
  color: #2e4042;
  margin: 20px 0;
}

.mdlTopWrp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.mdltitle {
  font-size: 21px;
  margin-left: 9px;
  font-weight: bold;
  color: #212529;
  margin-bottom: 0px;
  z-index: 1;
  text-transform: uppercase;
}

.mdlPrice {
  font-size: 21px;
  font-weight: bold;
  margin-left: 9px;
  color: #212529;
  margin-bottom: 0px;
  z-index: 1;
}

.menu-itemName {
  /* font-size: 20px; */
  /* color: #404040; */
  text-align: center;
  text-transform: uppercase;
  font-size: 14px !important;
  overflow: hidden;
  height: 38px !important;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 550px) {
  .fs-16.menu-itemName,
  .fs-16 .menu-itemName {
    font-size: 14px !important;
    overflow: hidden;
    height: 38px !important;
    text-overflow: ellipsis;
  }

  /* .cate-icons {
    height: 15px;
  } */

  .suggestBtn button {
    height: 35px;
    font-size: 15px;
  }

  .order-modal-title {
    font-size: 17px;
    text-transform: uppercase;
    color: #404040;
    height: 7%;
    text-align: center;
    font-weight: 600;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item_price {
    font-size: 17.5px;
    color: #2f3e3f;
    font-weight: bold;
  }

  .menu-itemPrice {
    font-size: 17.5px;
    color: #2f3e3f;
    font-weight: bold;
  }

  .leafsImg {
    width: 60px;
    right: 25px;
  }

  .hdngflex h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 400px) {
  @media only screen and (max-width: 380px) {
    .dish-cross-modal {
      top: 2px;
    }

    .hdngline {
      width: calc(138% - 6px);
    }
  }

  .menu-itemPrice {
    font-size: 14.5px;
  }

  .dish-img-container {
    height: 130px !important;
    width: 130px !important;
  }

  .dish-img {
    height: 130px !important;
  }

  .hdnglineblue {
    width: calc(100% - 6px);
  }
}

@media only screen and (max-width: 380px) {
  .dish-img {
    height: 100% !important;
    width: 118px !important;
  }

  .dish-img-container {
    height: 118px !important;
  }
}

@media only screen and (max-width: 310px) {
  .menu-itemPrice {
    font-size: 14.5px;
  }
}

@media only screen and (max-width: 530px) {
  .img-categroires {
    width: 100%;
    margin-left: 7px;
  }

  .menu-itemPrice {
    font-size: 14.5px;
  }
}

.otheroptionSection {
  padding-bottom: 140px;
  padding-top: 0px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 0.3s;
}

.otheroptionSection .divideCalc2 .totalPr2 {
  margin-bottom: 80px !important;
}

.mdlPrice {
  font-size: 21px;
  margin-left: 9px;
  font-weight: bold;
  color: #212529;
  margin-bottom: 0px;
  z-index: 1;
}

.totalPr,
.totalPr2,
.prSp {
  color: #00a94d;

  font-size: 53px;
  text-align: center;
}

.payWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 15px; */
}

.spSpan {
  width: 45%;
  height: 60px;
  padding: 0px 10px;
  background: #17b5c2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 5px 5px 10px -5px #000;
}

.spSpan2,
.otheroptionSection .spSpan {
  width: 45%;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background-color: #17b5c2;
  height: 60px;
  padding: 0 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 5px 5px 10px -5px #000;
}

.spSpan img {
  width: 50%;
  height: 100%;
  object-fit: contain;
}

.spSpan2 img,
.otheroptionSection .spSpan img {
  width: 100%;
  height: 47%;
  object-fit: contain;
}

.spSpan2 p,
.otheroptionSection .spSpan p {
  margin: 0;
}

.closeBtnDivide {
  width: 35px;
  height: 35px;
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1001;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.thankuText {
  color: #17b5c2;
  padding-top: 10px;
  font-size: 30px;
  font-weight: 600;
  margin-top: -15px;
}

.forOrderText {
  /* font-family: amithem; */
  font-size: 30px;
  font-weight: 500;
  /* padding-bottom: 20px; */

  margin-top: -17px;
}

.forOrderText p {
  margin-top: -17px;
}

.heartPaymentImg {
  /* border:1px solid black; */
  height: 100px;
  width: 150px;
}

.paymentlogo {
  /* border:1px solid black; */
  height: 50px;
  width: 200px;
}

.buttonCommon {
  /* border:1px solid black; */
  height: 50px;
  width: 200px;
  /* margin-top: 20px; */
  border-radius: 25px;
  color: white;
  /* font-size: 20px; */
  /* font-weight: bolder; */
  border: none;
}

.gastenbuchentrag {
  background-color: rgb(246, 180, 12);
}

.zuruck {
  background-color: red;
  margin-top: 8px;
}

.HIER {
  background-color: #17b5c2;
  /* height: 150px; */
  width: 325px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 14px;
  font-size: 25px;
  /* padding-bottom: 25px; */
}

.rightheartdiv {
  padding-left: 95px;
}

.hearttitle {
  color: #17b5c2;
  font-size: 30px;
  /* font-weight: bolder; */
}

.rightheart {
  /* border: 1px solid black ; */
  height: 40px;
  width: 30px;
  transform: skewY(25deg);
  margin-left: 14px;
}

.leftheart {
  /* border: 1px solid black ; */
  height: 40px;
  width: 30px;
  transform: skewY(149deg);
  margin-right: 14px;
}

.blacktitle {
  font-size: 35px;
  line-height: 64px;
}

p {
  margin-top: -22px;
}

.leftheartdiv {
  margin-top: -18px;
}

.timerpayment {
  /* border: 1px solid black; */
  /* height:100px; */
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
}

.timer-clock {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rechnungBtn {
  border-radius: 20px;
  border: none;
  color: #17b5c2;
  font-weight: 600px;
  width: 200px;
  margin-top: 10px;
}

.orderModalText {
  font-size: 34px;
  font-weight: 600;
  color: rgb(85, 85, 85);
  text-align: center;
  margin-bottom: 30px;
}

.divideCalc3 .countDown {
  background-color: #17b5c2;
  padding: 10px 0;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divideCalc3 .countDown i {
  color: #fff;
  margin-left: 15px;
  font-size: 40px;
}

.order-description {
  margin-top: 30px;
}

.order-description .desc {
  margin-top: 15px;
  font-size: 22px;
  text-transform: uppercase;
}

.divideBottomBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.divideBottomBtn button {
  width: 47%;
  height: 45px;
  border: none;
  border-radius: 7px;
  color: #fff;
  background: #17b5c2;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px -5px #000;
}

.timer {
  color: #fff;
  font-size: 33px;
  font-weight: 500;
  margin-bottom: 0;
}

.placebtn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.btncheck.btnblue {
  background-color: #33ab53;
}

.placebtn button {
  width: 32%;
  margin: 6px 0 !important;
}

.wallofFameImg {
  width: 41vw;
  height: calc(41vw + 20px);
  background-color: #f2e9de;
  margin: 7px 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.wallofFameImg img {
  width: 100%;
  height: 100%;
}

.divideBottomBtn button {
  width: 87%;
  height: 45px;
  border: none;
  border-radius: 7px;
  color: #fff;
  background: #17b5c2;
  font-size: 20px;
  font-weight: 500;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px -5px #000;
}


.firstNav {
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* =============PAYPAL MODAL CSS START============== */
divideCalc,
.divideCalc2,
.divideCalc3 {
  width: 100%;
  padding: 10px 15px;
  background: #ffffff;
  z-index: 1000;
  transition: 0.3s;
}

.paypal-sec {
  padding-top: 30px;
  margin-top: 85px;
}

.paypalHead {
  font-size: 25px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 25px;
}

/* .paypal-sec h1 {
  font-size: 25px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 25px;
} */
.divideCalc2 .paypal-sec p {
  color: #747474;
  font-size: 15px;
  text-align: center;
  margin-bottom: 25px;
}

.credit .divideCalc3 {
  padding: 10px 0;
}

.credit .divideCalc3 h1 {
  text-align: center;
}

.credit .divideCalc3 span {
  text-align: center;
  display: block;
  font-size: 45px;
  font-weight: 600;
}

.okBtn {
  width: 100px;
  height: 42px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background: #17b5c2;
  border: 1px solid #f8f8f8;
  float: right;
}

/* =============PAYPAL MODAL CSS Fin============== */

/* .is-sticky {
  position: fixed;
  top: 72;
  left: 0;
  width: 100%;
  z-index: 999;
} */
/* .firsNav .nav-item {
  font-size: 17px;
  text-transform: uppercase;
  color: #2f3e3f;
  text-decoration: none;
  font-weight: bold;
  font-family: "Barlow Condensed", sans-serif;
} */
@media only screen and (min-width: 600px) {
  .btnCreatJoin {
    display: block;
    margin: auto;
  }
}

@media print {
  .noprint {
    visibility: hidden !important;
    /* display: none !important; */
  }
}

.hearttitlecomplete {
  width: 200px;
  height: 65px;
  margin-top: 15px;
}

.hearttitlecompleteimg {
  width: 200px;
  height: 135px;
}

.paymentlogocomplete {
  height: 50px;
  width: 200px;
  margin-top: 35px;
}
